import LikeRewardPoint from "../../Components/shareRewardPoints/LikeRewardPoint";
import ShareRewardPoints from "../../Components/shareRewardPoints/ShareRewardPoints";

function LikeRewardPointsPage() {

  return (
    <>
      <LikeRewardPoint />
    </>
  )
}
export default LikeRewardPointsPage;