import React from 'react'
import OverViewCallLog from '../../Components/callLogOverview/OverViewCallLog'

function CallOverView() {
    return (
        <>
            <OverViewCallLog />
        </>
    )
}

export default CallOverView