import React from 'react'
import GoogleRecaptcha from '../../../../Components/setup&Configuration/google/googleRecaptcha/GoogleRecaptcha'

function GoogleRecaptchaPage() {
    return (
        <>
            <GoogleRecaptcha />
        </>
    )
}

export default GoogleRecaptchaPage