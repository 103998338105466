import ManualPaymentMethord from "../../Components/manualPaymentMethord/ManualPaymentMethord"


function ManualPaymentMethordPage() {
    return (
        <>
        <ManualPaymentMethord/>
        </>
    )
}
export default ManualPaymentMethordPage