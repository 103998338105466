import React from 'react'
import AddPurchaseList from '../../Components/addPurchaseList/AddPurchaseList'

function AddPurchaseListPage() {
    return (
        <>
            <AddPurchaseList />
        </>
    )
}

export default AddPurchaseListPage