import CouponInformation from "../../Components/couponInformation/CouponInformation";
import CouponInformationAdding from "../../Components/couponInformationAdding/CouponInformationAdding";

function CouponInformationAddingPage() {
    return (
        <>
            <CouponInformationAdding />
        </>
    )
}
export default CouponInformationAddingPage;