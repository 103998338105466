import React from 'react'
import ShippingCities from '../../../../Components/setup&Configuration/shipping/shippingCities/ShippingCities'

function ShippingCitiesPage() {
    return (
        <>
            <ShippingCities />
        </>
    )
}

export default ShippingCitiesPage