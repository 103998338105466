import React from 'react'
import GoogleAnalytical from '../../../../Components/setup&Configuration/google/googleAnalytical/GoogleAnalytical'

function AnalyticalPage() {
    return (
        <>
            <GoogleAnalytical />
        </>
    )
}

export default AnalyticalPage