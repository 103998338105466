import AffiliateConfiguration from "../../Components/affiliateConfiguration/AffiliateConfiguration"
import AffiliateForm from "../../Components/AffiliatedForm/AffiliatedForm"

function AfiliatedConfigurationPage() {
    return (
        <>
            <AffiliateConfiguration />
        </>
    )
}
export default AfiliatedConfigurationPage