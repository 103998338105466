import MeniFestList from "../../../Components/booking/menifestList/MeniFestEntry"


function ManiListPage () {
    return (
        <>
        <MeniFestList/>
        </>
    )
}
export default ManiListPage