import VerificationForm from "../../Components/verificationForm/VerificationForm"

function VerificationFormPage() {
    return (
        <>
        <VerificationForm/>
        </>
    )
}
export default VerificationFormPage
