import InHouseOrder from "../../Components/inHouseOrders/InHouseOrders"


function InHouseOrderPage() {
    return (
        <>
        <InHouseOrder/>
        </>
    )
}
export default InHouseOrderPage