import React from 'react'
import ShippingConfiguration from '../../../../Components/setup&Configuration/shipping/shippingConfigurationPage/ShippingConfiguration'


function ShippingConfigurationPage() {
    return (
        <>
            <ShippingConfiguration />
        </>
    )
}

export default ShippingConfigurationPage