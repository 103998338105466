import React from 'react'
import Size from '../../Components/size/Size'

function SizePage() {
    return (
        <>
            <Size />
        </>
    )
}
export default SizePage