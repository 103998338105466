import React from 'react'
import { AiFillEdit } from 'react-icons/ai'
import { GrView } from 'react-icons/gr'
function BuildingMaster() {

    return <>
        <div className="px-15px px-lg-25px">
            <div className="aiz-titlebar text-left mt-2 mb-3">
                <div className='card p-5'>
                    <h2 className='mb-5'>BUILDING NAME</h2>
                    <div className="row align-items-center">
                        <div className='col-lg-4 col-md-12 col-mb-5'>
                            <span>
                                <input type="text" className="form-control" id="exampleDateOfManufacture" aria-describedby="emailHelp" placeholder='Enter Building Name' />
                            </span>
                        </div>
                        <div className="col-lg-1 col-mb-">
                            <div >
                                <span>
                                    <input type="radio" name='buildingMaster' id="active" aria-describedby="emailHelp" />
                                </span>
                                <span>
                                    <label htmlFor='active'><b>Active</b> </label>
                                </span>
                            </div>
                        </div>
                        <div className="col-lg-1">
                            <div>
                                <span>
                                    <input type="radio" name='buildingMaster' id="inactive" aria-describedby="emailHelp" />
                                </span>
                                <span>
                                    <label htmlFor='inactive'><b> In Active</b> </label>
                                </span>
                            </div>

                        </div>

                        <div className="col-lg-3 d-flex justifycontentCenter">
                            <button className="btn btn-primary mr-3">Save</button>
                            <button className="btn btn-danger">Cancel</button>
                        </div>
                        {/* </form> */}
                    </div>


                </div>
                <div className="card-body">
                    <h2>Lists</h2>
                    <table className="table table-3 position-relative">
                        <thead>
                            <tr>
                                <th class="table-secondary" scope="col">Building Name</th>
                                <th class="table-secondary" scope="col">YES/NO</th>
                                <th class="table-secondary" scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>shubham</td>
                                <td>10</td>
                                <td>
                                    <button type="button" className="btn btn-soft-danger btn-icon btn-circle mr-2 btn-sm btn-circle-2" title="delete" fdprocessedid="yghhlt">
                                        <GrView />
                                    </button>
                                    <button type="button" className="btn btn-soft-danger btn-icon btn-circle btn-sm btn-circle-2" title="delete" fdprocessedid="yghhlt">
                                        <AiFillEdit />
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </>
}

export default BuildingMaster
