import React from 'react'
import FacebookChat from '../../../../Components/setup&Configuration/facebook/facebookChat/FacebookChat';

function FacebookChatPage() {
    return (
        <>
            <FacebookChat />
        </>
    )
}

export default FacebookChatPage;