import AllRoles from "../../Components/allStaffs/allRoles/AllRoles";
import AllStaffs from "../../Components/allStaffs/AllStaffs";


function StaffPermissionPage() {

  return (
    <>
      <AllRoles />
    </>
  )
}
export default StaffPermissionPage;