import ProductsConversation from "../../Components/productsConversation/ProductsConversation";

function ProductsQuerriesPage() {
    const data = {
        title: "Product Queries"
    }

    return (
        <>
            <ProductsConversation data={data} />
        </>
    )
}
export default ProductsQuerriesPage;