import React from 'react'
import ComplaintsDashboard from '../../Components/complaintsDashboard/ComplaintsDashboard'
function ComplaintsDashboardPages() {
    return (
        <div>
            < ComplaintsDashboard />
        </div>
    )
}

export default ComplaintsDashboardPages
