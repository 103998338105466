import React from 'react'
import ShippingCarrier from '../../../../Components/setup&Configuration/shipping/shippingCarrier/ShippingCarrier'

function ShippingCarrierPage() {
  return (
    <>
      <ShippingCarrier />
    </>
  )
}

export default ShippingCarrierPage