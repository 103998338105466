import React from 'react'
import GoogleFirebase from '../../../../Components/setup&Configuration/google/googleFirebase/GoogleFirebase'

function GoogleFirebasePage() {
    return (
        <>
            <GoogleFirebase />
        </>
    )
}

export default GoogleFirebasePage