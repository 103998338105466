
import ShareRewardPoints from "../../Components/shareRewardPoints/ShareRewardPoints";

function ShareRewardPointsPage() {
  
  return (
    <>
      <ShareRewardPoints />
    </>
  )
}
export default ShareRewardPointsPage;