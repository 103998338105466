import React from 'react'
import AddPosComp from '../../../Components/posSystem/addPos/AddPosComp'

function AddPos() {
    return (
        <>
            <AddPosComp />
        </>
    )
}
export default AddPos