import React from 'react'
import ShippingZones from '../../../../Components/setup&Configuration/shipping/shippingZones/ShippingZones'

function ShippingZonesPage() {
    return (
        <>
           <ShippingZones />
        </>
    )
}
export default ShippingZonesPage