// import AddNewCalls from "../../components/addnewcalls/addNewCalls"
import AddNewCalls from "../../Components/addnewcalls/addNewCalls"

function Addnewcalls() {
    return (
        <>
            <AddNewCalls />
        </>
    )
}
export default Addnewcalls