import LanguageEdit from "../../Components/languageEdit/LanguageEdit";

function LanguageEditPage() {
    return (
        <>
            <LanguageEdit />
        </>
    )
}
export default LanguageEditPage;
