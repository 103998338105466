import OfflineWalletRecharge from "../../Components/offlineWalletRecharge/OfflineWalletRecharge"


function OfflineWalletRechargePage() {
    return (
        <>
        <OfflineWalletRecharge/>
        </>
    )
}
export default OfflineWalletRechargePage