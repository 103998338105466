import React from 'react'
import FacebookComment from '../../../../Components/setup&Configuration/facebook/facebookComment/FacebookComment'

function FacebookCommentPage() {
    return (
        <>
            <FacebookComment />
        </>
    )
}

export default FacebookCommentPage