import CustomerList from "../../Components/customerList/CustomerList"


function CustomerListPage() {
    return (
        <>
            <CustomerList />
        </>
    )
}
export default CustomerListPage