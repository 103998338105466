import React from 'react'
import GoogleMap from '../../../../Components/setup&Configuration/google/googleMap/GoogleMap'

function GoogleMapPage() {
    return (
        <>
            <GoogleMap />
        </>
    )
}

export default GoogleMapPage