import SellerBanner from "../../Components/sellerBanner/SellerBanner"


function SellerBannerPage() {
    return (
        <>
        <SellerBanner/>
        </>
    )
}
export default SellerBannerPage