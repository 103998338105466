import React from 'react'
import ListPurchase from '../../Components/listPurchase/ListPurchase'

function ListPurchasePage() {
    return (
        <>
            <ListPurchase />
        </>
    )
}

export default ListPurchasePage