import React from 'react'
import ShippingCountries from '../../../../Components/setup&Configuration/shipping/shippingCountries/ShippingCountries'

function ShippingCountriesPage() {
    return (
        <>
            <ShippingCountries />
        </>
    )
}

export default ShippingCountriesPage